import { useState, useEffect } from "react";
import { FormRow, Alert } from "../components";
import Wrapper from "../assets/wrappers/RegisterPage";
import { useAppContext } from "../context/appContext";
import liff from "@line/liff";
import defaultImage from "../assets/images/blank-profile-picture.svg";
import { LIFF_ID_REGISTER } from "../assets/lineIffUrls";
import { VisibilityRounded, VisibilityOffRounded } from "@mui/icons-material";
const initialState = {
  username: "",
  password: "",
  pictureUrl: "",
  displayName: "",
  userId: "",
  idToken: "",
  accessToken: "",
  statusMessage: "",
  OS: "",
  email: null,
  isLoggedIn: false,
};

const Register = () => {
  const [values, setValues] = useState(initialState);
  const [password, setPassword] = useState(false);

  const { showAlert, displayAlert, setupUser } = useAppContext();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!values.username || !values.password || !values.displayName) {
      displayAlert();
      return;
    }
    const currentUser = values;
    if (values.idToken) {
      setupUser({
        currentUser,
        endPoint: "register",
        alertText: "register success ! ...",
        liff: liff,
      });
    }
  };

  const initLine = () => {
    liff.init({ liffId: LIFF_ID_REGISTER }).then(() => {
      if (liff.isLoggedIn()) {
        liff.getProfile().then((profile) => {
          setValues({
            ...values,
            pictureUrl: profile.pictureUrl,
            displayName: profile.displayName,
            userId: profile.userId,
            idToken: liff.getIDToken(),
            accessToken: liff.getAccessToken(),
            OS: liff.getOS(),
            email: liff.getDecodedIDToken().email || undefined,
            statusMessage: profile.statusMessage,
            isLoggedIn: liff.isLoggedIn(),
          });
        });
      } else {
        liff.login();
      }
    });
  };

  useEffect(() => {
    return () => initLine();
  }, []);

  return (
    <Wrapper>
      <div className="block bcg-black"> </div>
      <div className="block">
        <div className="container" style={{ marginTop: "-300px" }}>
          <form className="form" onSubmit={onSubmit}>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  values.pictureUrl !== "" ? values.pictureUrl : defaultImage
                }
                alt="user"
                className="user-img"
              />
            </div>
            {showAlert && <Alert />}
            <FormRow
              type="text"
              labelText="user id"
              name="username"
              value={values.username}
              handleChange={handleChange}
            />

            <div className="form-row">
              <label className="form-label">password</label>
              <div
                className="form-search-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <input
                  type={password ? "text" : "password"}
                  value={values.password}
                  name="password"
                  onChange={handleChange}
                  className="form-input"
                />
                <div
                  className="icon-container"
                  onClick={() => setPassword(!password)}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  {password ? (
                    <VisibilityRounded size={22} className="icon" />
                  ) : (
                    <VisibilityOffRounded size={22} className="icon" />
                  )}
                </div>
              </div>
            </div>

            <p className="user-value" style={{ textAlign: "left" }}>
              Line Display Name : {values.displayName}
            </p>
            <p className="user-value" style={{ textAlign: "left" }}>
              Line uid : {values.userId.substring(0, values.userId.length - 3)}
              ...
            </p>
            <button
              type="submit"
              className="btn btn-block"
              disabled={!values.isLoggedIn}
            >
              {!values.isLoggedIn ? "loading..." : "register"}
            </button>
            <button
              type="button"
              className="btn btn-block btn-hipster"
              disabled={!values.isLoggedIn}
              onClick={() => {
                liff.closeWindow();
              }}
            >
              {!values.isLoggedIn ? "loading..." : "close"}
            </button>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};
export default Register;
