import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Error, Register, Timestamp, Leave } from "./pages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Timestamp />} />
        <Route path="/register" element={<Register />} />
        <Route path="/timestamp" element={<Timestamp />} />
        <Route path="/leave" element={<Leave />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
