import { useState, useEffect, useRef } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  CircleF,
} from "@react-google-maps/api";
import { setDefaults, geocode, RequestType } from "react-geocode";
// import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
// import Circle from "../components/Circle";
import liff from "@line/liff";
import { RefreshOutlined } from "@mui/icons-material";
import { Alert } from "../components";
import Wrapper from "../assets/wrappers/TimestampPage";
import WrapperTop from "../assets/wrappers/TimestampPageNavbar";
import { useAppContext } from "../context/appContext";
import logoImage from "../assets/images/gl_logo.jpg";
import { LIFF_ID_TIMESTAMP } from "../assets/lineIffUrls";
import Swal from "sweetalert2";

const initState = {
  userId: "",
  pictureUrl: "",
};

const libraries = ["geometry", "places"];
const center = {
  lat: 13.8350898,
  lng: 100.5506155,
};

const circleOptions = {
  strokeColor: "#41d5de",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#41d5de",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 80, // Radius in meters
  zIndex: 1,
};
const GOOGLE_MAPS_API_KEY = "AIzaSyD4hKTcLl9Fh7hT7jBi-Zb4yiuJVxUoalE";
setDefaults({
  key: GOOGLE_MAPS_API_KEY, // Your API key here.
  language: "th", // Default language for responses.
  region: "th", // Default region for responses.
  location_type: "ROOFTOP",
});
const Timestamp = () => {
  const {
    isLoading,
    profile,
    showAlert,
    displayAlert,
    loadData,
    createTimestamp,
  } = useAppContext();

  const [values, setValues] = useState(initState);
  const [time, setTime] = useState(new Date());
  const [currentLocation, setCurrentLocation] = useState({
    location: { lat: 0, lng: 0 },
    isWithinCircle: false,
    distance: 0,
    address: "No adress",
  });

  const mapRef = useRef();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const onLoadMap = (map) => {
    mapRef.current = map;
  };

  function computeDistance(lat1, lon1, lat2, lon2) {
    const R = 6371000; // Radius of the Earth in meters
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in meters
  }
  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const distance = computeDistance(
            latitude,
            longitude,
            center.lat,
            center.lng
          );

          try {
            const response = await geocode(
              RequestType.LATLNG,
              `${latitude},${longitude}`
            );
            const address = response.results[0].formatted_address;

            setCurrentLocation({
              location: { lat: latitude, lng: longitude },
              isWithinCircle: distance <= circleOptions.radius,
              distance: distance,
              address: address,
            });
          } catch (error) {
            console.error("Error getting address:", error);
          }
        },
        (error) => {
          console.error("Error obtaining location", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!values.userId) {
      displayAlert();
      return;
    }

    const timestamp = {
      user_id: profile.id,
      dateAt: `${time.getFullYear()}-${(time.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${time.getDate().toString().padStart(2, "0")}`,
      start_time: profile.start_time,
      key_time: time.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }),
      key_location: currentLocation.location,
      key_address: currentLocation.address,
      wfh: currentLocation.isWithinCircle ? "N" : profile.wfh,
    };

    createTimestamp({ values, timestamp, liff });
  };

  const fetchData = async (profile) => {
    loadData({
      profile,
      endPoint: "profile",
      alertText: "Load Data Please wait...!!",
    });
  };

  const initLine = async () => {
    liff.init({ liffId: LIFF_ID_TIMESTAMP }).then(() => {
      if (liff.isLoggedIn()) {
        liff.getProfile().then((profile) => {
          fetchData(profile);
          setValues({
            ...values,
            userId: profile.userId,
            pictureUrl: profile.pictureUrl,
          });
        });
      } else {
        liff.login();
      }
    });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    const timer1 = setTimeout(() => {
      if (liff.isInClient()) {
        liff.closeWindow();
      }
    }, 25000); // 5000 milliseconds = 5 seconds

    return () => {
      initLine();
      fetchLocation();
      clearInterval(timer);
      clearTimeout(timer1);
    };
  }, []);

  // const onLoadMarker = async (marker) => {
  //   if (marker) {
  //     const markerLatLng = new window.google.maps.LatLng(
  //       marker.getPosition().lat().toFixed(12),
  //       marker.getPosition().lng().toFixed(12)
  //     );

  //     const circleCenterLatLng = new window.google.maps.LatLng(
  //       center.lat,
  //       center.lng
  //     );

  //     const distance =
  //       window.google.maps.geometry.spherical.computeDistanceBetween(
  //         markerLatLng,
  //         circleCenterLatLng
  //       );
  //     const geocoder = new window.google.maps.Geocoder();
  //     geocoder.geocode(
  //       { location: marker.getPosition() },
  //       (results, status) => {
  //         if (status === "OK" && results[0]) {
  //           const address = results[0].formatted_address;
  //           setCurrentLocation({
  //             ...currentLocation,
  //             isWithinCircle: distance <= circleOptions.radius ? true : false,
  //             distance: distance,
  //             address: address,
  //           });
  //         } else if (status === "ZERO_RESULTS") {
  //           console.error("No address found for the provided coordinates.");
  //         } else {
  //           console.error(
  //             "Geocode was not successful for the following reason:",
  //             status
  //           );
  //         }
  //       }
  //     );
  //     // console.log("lat :", markerLatLng.lat().toFixed(12));
  //     // console.log("lng :", markerLatLng.lng().toFixed(12));
  //     // console.log(
  //     //   "lat circle:",
  //     //   circleCenterLatLng.lat().toFixed(12)
  //     // );
  //     // console.log(
  //     //   "lng circle:",
  //     //   circleCenterLatLng.lng().toFixed(12)
  //     // );
  //     // console.log(distance, circleOptions.radius);
  //     // console.log("address", address);

  //     // setCurrentLocation({
  //     //   ...currentLocation,
  //     //   isWithinCircle: distance <= circleOptions.radius ? true : false,
  //     //   distance: distance,
  //     //   address: "",
  //     // });
  //   }
  // };
  if (liff.getOS() === "web") {
    return (
      <Wrapper>
        <div className="block bcg-black"></div>
        <div className="block">
          <div className="container" style={{ marginTop: "-150px" }}>
            <form className="form">
              <div style={{ textAlign: "center" }}>
                <div>
                  <h2
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "25px",
                    }}
                  >
                    Please Log-in Mobile Only !
                  </h2>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="block bcg-black"></div>
      <div className="block">
        <div className="container" style={{ marginTop: "-350px" }}>
          <form className="form" onSubmit={onSubmit}>
            <div>
              {/* <h6>{JSON.stringify(currentLocation)}</h6>
              <h6>{JSON.stringify(currentLocation.isWithinCircle)}</h6>
              <h6>{JSON.stringify(profile)}</h6> */}
            </div>
            <WrapperTop>
              <div className="nav-center">
                <div className="top-left">
                  <div className="icon-container">
                    <img
                      src={values.pictureUrl || logoImage}
                      alt="logo"
                      className="img-avatar"
                    />
                  </div>
                  <div className="icon-container">
                    <div className="text-btn">
                      {profile
                        ? `${profile.id} : ${profile.username} ${
                            profile.wfh === "Y" ? "wfh" : ""
                          }`
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="top-right">
                  <div className="icon-container">
                    <button
                      className="refresh-btn"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      <RefreshOutlined />
                    </button>
                  </div>
                </div>
              </div>
            </WrapperTop>

            <div style={{ textAlign: "center" }}>
              <div>
                <h6
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    fontSize: "10px",
                    marginTop: "5px",
                  }}
                >
                  {currentLocation.address.slice(0, 100)} ...
                </h6>
              </div>
              <div className="img-container">
                <img src={logoImage} alt="logo" className="logo-img" />
              </div>
              {showAlert && <Alert />}
              <div className="time-circle">
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "15px",
                    fontWeight: "bold",
                    marginTop: "5px",
                  }}
                >
                  {`${time.getDate().toString().padStart(2, "0")}-${(
                    time.getMonth() + 1
                  )
                    .toString()
                    .padStart(2, "0")}-${time.getFullYear()}`}
                </span>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginTop: "5px",
                  }}
                >
                  {time.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false,
                  })}
                </span>
              </div>
            </div>

            {!isLoaded && (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <span
                  className="skeleton-loader"
                  style={{ width: "100%", height: "250px" }}
                />
              </div>
            )}
            {/* <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <APIProvider apiKey="AIzaSyD4hKTcLl9Fh7hT7jBi-Zb4yiuJVxUoalE">
                <Map
                  zoom={18}
                  center={center}
                  style={{
                    width: "100%",
                    height: "250px",
                  }}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    mapId: "DEMO_MAP_ID,",
                  }}
                />
                <AdvancedMarker
                  title="my location"
                  position={center}
                  style={{ transform: "translate(50%, 100%)" }}
                />
                <Circle center={center} options={circleOptions} />
              </APIProvider>
            </div> */}
            {isLoaded && (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: "250px",
                  }}
                  center={currentLocation.location || center}
                  onLoad={onLoadMap}
                  zoom={17}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                  }}
                >
                  <MarkerF
                    title="my location"
                    position={currentLocation.location}
                    style={{ transform: "translate(50%, 100%)" }}
                    // onLoad={onLoadMarker}
                  >
                    {/* <InfoWindowF
                      position={currentLocation.location}
                   
                    >
                      <div className="info-window-content">
                        <p>
                          {profile
                            ? `${profile.id} : ${profile.username} `
                            : ""}
                        </p>
                      </div>
                    </InfoWindowF> */}
                  </MarkerF>

                  <CircleF center={center} options={circleOptions} />
                </GoogleMap>
              </div>
            )}

            <button
              type="submit"
              className={`btn btn-block ${
                profile
                  ? profile.wfh === "Y" || currentLocation.isWithinCircle
                    ? ""
                    : "btn-danger"
                  : "btn-danger"
              }`}
              disabled={
                isLoading
                  ? true
                  : profile
                  ? profile.wfh === "Y" || currentLocation.isWithinCircle
                    ? false
                    : true
                  : true
              }
            >
              {isLoading
                ? "Loading..."
                : profile
                ? profile.wfh === "Y" || currentLocation.isWithinCircle
                  ? "ลงเวลา "
                  : "นอกพื้นที่..."
                : "Loading data ..."}
            </button>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default Timestamp;
