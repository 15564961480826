import styled from "styled-components";

const Wrapper = styled.nav`
  width: 100%;
  height: 3rem;
  box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.1);
  background: var(--background-fouth-color);
  position: sticky;
  top: 0;
  margin-top: -1.5rem;
  /* background-color: red; */

  .logo {
    display: flex;
    align-items: center;
    width: 35px;
  }

  .nav-center {
    /* height: 100%; */
    padding: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .refresh-btn {
    background: var(--primary-300);
    border-color: transparent;
    font-size: 1.75rem;
    border-radius: 5px;
    color: var(--primary-700);
    cursor: pointer;
    display: flex;

    align-items: center;
    border-radius: var(--borderRadius);
    box-shadow: var(--shadow-2);
    transition: var(--transition);
    text-transform: capitalize;
    width: 35px;
    height: 25px;
    margin-top: -5px;
    margin-right: -27utilpx;
    justify-content: center;
  }
  .refresh-btn:hover {
    background: var(--primary-700);
    color: var(--primary-300);
    box-shadow: var(--shadow-3);
  }
  .text-btn {
    background: var(--primary-200);
    border-color: transparent;
    font-size: 0.75rem;
    border-radius: 5px;
    margin-left: 5px;
    padding-block: 5px;
    padding-inline: 5px;
    color: var(--primary-700);

    /* cursor: pointer; */
    /* display: flex;
    align-items: center; */
  }

  .top-left {
    display: flex;
    align-items: center;
  }

  .top-right {
    display: flex;
    align-items: center;
  }

  .icon-container {
    position: relative;
    margin-right: 0px;
    color: var(--text-second-color);
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 0.1rem;
    position: relative;
    box-shadow: var(--shadow-2);
  }

  .icon-badge {
    width: 17px;
    height: 17px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: var(--clr-red-dark);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }

  .logo-text {
    display: none;
    margin: 0;
  }
  .img-avatar {
    width: 40px;
    height: 40px;
    margin-top: 5px;
    margin-left: 5px;
    border-radius: 50%;
  }
  //กว้าง >= 992 ทำ
  //“min-width: 1024px” จะหมายถึงอุปกรณ์ที่มี width มากกว่าหรือเท่ากับ 1024px
  //“max-width: 320px” จะหมายถึงอุปกรณ์ที่มี width น้อยกว่าหรือเท่ากับ 320px.
  @media (min-width: 992px) {
    position: sticky;
    top: 0;

    .nav-center {
      width: 100%;
    }
    .logo {
      display: none;
    }
    .logo-text {
      display: block;
    }
    /* .btn-container {
      position: relative;
      margin-right: 20px;
    } */
  }
`;
export default Wrapper;
