export const DISPLAY_ALERT = "SHOW_ALERT";
export const DISPLAY_ALERT_TEXT = "SHOW_ALERT_TEXT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const SETUP_USER_BEGIN = "SETUP_USER_BEGIN";
export const SETUP_USER_SUCCESS = "SETUP_USER_SUCCESS";
export const SETUP_USER_ERROR = "SETUP_USER_ERROR";

export const SETUP_LOAD_DATA_BEGIN = "SETUP_LOAD_DATA_BEGIN";
export const SETUP_LOAD_DATA_SUCCESS = "SETUP_LOAD_DATA_SUCCESS";

export const SETUP_TIMESTAMP_BEGIN = "SETUP_TIMESTAMP_BEGIN";
export const SETUP_TIMESTAMP_SUCCESS = "SETUP_TIMESTAMP_SUCCESS";
export const SETUP_TIMESTAMP_ERROR = "SETUP_TIMESTAMP_ERROR";

export const SETUP_LEAVE_BEGIN = "SETUP_LEAVE_BEGIN";
export const SETUP_LEAVE_SUCCESS = "SETUP_LEAVE_SUCCESS";
export const SETUP_LEAVE_ERROR = "SETUP_LEAVE_ERROR";

export const SETUP_TRNFER_BEGIN = "SETUP_TRNFER_BEGIN";
export const SETUP_TRNFER_SUCCESS = "SETUP_TRNFER_SUCCESS";
export const SETUP_TRNFER_ERROR = "SETUP_TRNFER_ERROR";
